@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 35px;
}
